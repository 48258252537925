import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import PauseIcon from "@mui/icons-material/Pause";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RepeatIcon from "@mui/icons-material/Repeat";
import StopIcon from "@mui/icons-material/Stop";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { MdBluetooth } from "react-icons/md";
import { GiUsbKey } from "react-icons/gi";
import RadioIcon from '@mui/icons-material/Radio';
import StreamIcon from '@mui/icons-material/Stream';
import { EButtonIcons } from "../local-interfaces/local-enum";
import CurtainsIcon from '@mui/icons-material/Curtains';
import CurtainsClosedIcon from '@mui/icons-material/CurtainsClosed';
import OnlinePredictionSharpIcon from '@mui/icons-material/OnlinePredictionSharp';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import { Box, Typography } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
export function useOnIcons() {
  return {
    images: getImage(),
  };

  function getImage(): (iconName: string, fontSizei: number) => any {
    return (iconName: string, fontSize) => {
      switch (iconName) {
        case EButtonIcons.play:
          return <PlayArrowIcon style={{ fontSize }} />;

        case EButtonIcons.stop:
          return <StopIcon style={{ fontSize }} />;

        case EButtonIcons.pause:
          return <PauseIcon style={{ fontSize }} />;

        case EButtonIcons.backward:
          return <FastRewindIcon style={{ fontSize }} />;

        case EButtonIcons.mute:
          return <VolumeOffIcon style={{ fontSize }} />;

        case EButtonIcons.forward:
          return <FastForwardIcon style={{ fontSize }} />;

        case EButtonIcons.light:
          return <LightbulbIcon style={{ fontSize }} />;

        case EButtonIcons.reboot:
          return <PowerSettingsNewIcon style={{ fontSize }} />;

        case EButtonIcons.repeat:
          return <RepeatIcon style={{ fontSize }} />;

        case 'media-bluetooth-on':
        case EButtonIcons.bluetooth:
          return <MdBluetooth style={{ fontSize }} />;

        case EButtonIcons.usb:
          return <GiUsbKey style={{ fontSize }} />;

        case EButtonIcons.radio:
          return <RadioIcon style={{ fontSize }} />

        case 'view-stream':
        case EButtonIcons.stream:
          return <StreamIcon style={{ fontSize }} />

        case EButtonIcons.curtains:
          return <CurtainsIcon style={{ fontSize }} />
        case EButtonIcons.curtains_closed:
          return <CurtainsClosedIcon style={{ fontSize }} />

        case EButtonIcons.light_group:
          return <OnlinePredictionSharpIcon style={{ fontSize }} />

        case EButtonIcons.light_group_off:
          return <LightbulbOutlinedIcon style={{ fontSize }} />

        case EButtonIcons.volume_high:
          return <VolumeUpIcon style={{ fontSize }} />

        case EButtonIcons.volume_plus:
          return <Box sx={{ display: 'flex', alignItems: 'center' }}><VolumeMuteIcon style={{ fontSize }} /><Typography style={{ fontSize: fontSize / 100 * 60, marginLeft: -40, marginBottom: 0 }}>+</Typography>
          </Box>
        case EButtonIcons.volume_minus:
          return <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <VolumeMuteIcon style={{ fontSize }} />
            <RemoveIcon style={{ fontSize: fontSize / 100 * 40, marginLeft: -40 }} />
          </Box>

        default:
          return <DangerousIcon style={{ fontSize }} />;
      }
    };
  }
}
