import { CSSProperties, FC, useEffect, useState } from "react";
import { useOnIcons } from "../../../../../hooks/useOnIcons";
import { IComponent } from "../../../../../common-interfaces/interfaces";
import { Box } from "@mui/material";


const ComponentIcon: FC<{ comp: IComponent | undefined, size?: number, style?: CSSProperties | undefined; }> = ({ comp, size = 50, style }) => {
  const [compIcon, setCompIcon] = useState<string>();
  const [fontSize, setFontSize] = useState<number>();
  const { images } = useOnIcons()


  useEffect(() => {
    if (!comp) {
      return;
    }
    setCompIcon(comp?.icon);
    const tempFont = Math.min(comp.width, comp.height) * size;
    setFontSize(tempFont);
  }, [comp]);

  return compIcon && fontSize ? <Box style={style}>{images(compIcon, fontSize)}</Box> : <></>
}

export default ComponentIcon;
