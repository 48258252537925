import { IAutocompleteOPT } from "./local-interfaces";

export enum OS {
	UNKNOW = 'Unknown',
	WINDOWS = 'Windows',
	MAC_OS = 'MacOS',
	LINUX = 'Linux',
	ANDROID = 'Android',
	IOS = 'iOS'
}
export enum EButtonIcons {
	play = 'play',
	pause = 'pause',
	forward = 'skip-forward',
	backward = 'skip-backward',
	stop = 'stop',
	repeat = 'repeat',
	reboot = 'power',
	light = 'lightbulb-on',
	mute = 'volume-mute',
	bluetooth = 'bluetooth',
	usb = 'usb-flash-drive',
	radio = 'radio',
	stream = 'cast-audio-variant',
	light_group = 'lightbulb-group',
	light_group_off = 'lightbulb-group-off-outline',
	volume_high = 'volume-high',
	volume_medium = 'volume-medium',
	volume_low = 'volume-low',
	volume_minus = 'volume-minus',
	volume_plus = 'volume-plus',
	volume_mute = 'volume-mute',
	volume_off = 'volume-off',
	curtains = 'curtains',
	curtains_closed = 'curtains-closed'
}

