import { Box, Button, Paper, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { EComponentType } from "../../../common-interfaces/interfaces";
import { rightWidthAtom, selectedComponentIDsAtom } from "../../../recoil/atoms";
import "../../../styles/EditdashboardRightBar.css";
import EditOneComponent from "../../../common-components/editComponents/editOneComponent/EditOneComponent";
import EditMoreComponents from "./moreThanOneComponent/EditMoreComponents";
import { useOnGet } from "../../../hooks/useOnGet";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { currentSelectedComponentAtom } from "../../../recoil/Atom";

const buttonStyle = { height: "80px", margin: "auto 0", borderRadius: "5px !important", opacity: "0.5" }

const EditdashboardRightBar = () => {
  const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);
  const [rightWidth, setRightWidth] = useRecoilState(rightWidthAtom);
  const [_, setComp] = useRecoilState(currentSelectedComponentAtom)
  const resetCompAtom = useResetRecoilState(currentSelectedComponentAtom)

  const GET = useOnGet();

  const handleRightBar = () => {
    rightWidth === 0 ? setRightWidth(500) : setRightWidth(0);
    setTimeout(() => {
      const resizeEvent = new Event("resize");
      window.dispatchEvent(resizeEvent);
    }, 10);
  };

  const RenderHandler = useCallback(() => {
    const selectedComponentLength = selectedComponentIDs.length
    if (selectedComponentLength > 1) return <EditMoreComponents />
    if (selectedComponentLength === 1) return <EditOneComponent />
    resetCompAtom()
    return <Typography>Please select a component to edit</Typography>;
  }, [selectedComponentIDs]);

  return (
    <Paper elevation={0} className="sidebar">
      <Box minWidth={rightWidth + 20} height="100%" display="flex">
        <Box width={"20px"} height="100%" display={"flex"} borderLeft={rightWidth ? "" : "3px solid var(--grey-color)"} borderRight={rightWidth ? "3px solid var(--grey-color)" : ""}>
          <Button className="sidebarHandler" onClick={handleRightBar} sx={buttonStyle}>
            {rightWidth ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </Button>
        </Box>
        <Box width={rightWidth} display={rightWidth ? "unset" : "none"} padding={1}>
          <RenderHandler />
        </Box>
      </Box>
    </Paper>
  );
}

export default EditdashboardRightBar
