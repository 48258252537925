import { Typography, Button, Grid } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useParams } from "react-router-dom";
import { selectedComponentIDsAtom, selectedInstallationAtom } from "../../../recoil/atoms";
import { useRecoilState } from "recoil";
import { currentSelectedComponentAtom } from "../../../recoil/Atom";
import HandleAddDevice from "../../devices/HandleAddDevice";
import EditMultiServiceHosts from "./EditMultiServiceHosts";
import ServiceRow from "../ServiceRow";
import ServiceFastCreate from "../editServices/rest/ServiceFastCreate";

const buttonStyle = { display: 'flex', mx: 'auto' }

const ServicesTable = () => {
    const { installationId, dashboardId, componentId } = useParams();
    const navigate = useNavigate();

    const [currentSelectedComponent] = useRecoilState(currentSelectedComponentAtom)
    const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);

    const navigateToEditComponentScreen = () => {
        navigate(`/installation/${installationId}/dashboard/${dashboardId}/component/${selectedComponentIDs[0]}`);
    }

    return (
        <Grid width="90%" minHeight={'30%'} container justifyContent={'center'} alignItems={'center'} gap={2} boxSizing="border-box" padding="10px" overflow="auto">
            {/* title */}
            <Grid item width={'100%'} >
                {componentId ?
                    <Typography className="underline-title">Services</Typography> :
                    <Button sx={buttonStyle} endIcon={<ArrowForwardIcon />} variant='outlined' onClick={navigateToEditComponentScreen} color="secondary">
                        <Typography color={'var(--secondary-color)'}>Services</Typography>
                    </Button>
                }
            </Grid>
            {/* rows */}
            {!currentSelectedComponent?.services?.length && <Typography color={'white'}>No service available, please add one</Typography>}
            <EditMultiServiceHosts component={currentSelectedComponent} />
            {currentSelectedComponent?.services?.map(service => <ServiceRow key={service._id} service={service} component={currentSelectedComponent} />)}
            < HandleAddDevice />
            <ServiceFastCreate />
        </Grid >
    )
}

export default ServicesTable