import Button from "@mui/material/Button";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Autocomplete, Box, TextField, Paper, } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { ControlWhiteDialog } from "../../../../theme";
import { currentSelectedComponentAtom, uneditableServiceAtom } from "../../../../recoil/Atom";
import { newServiceAtom } from "../../../../recoil/CreateServiceAtoms";
import { IComponent, IService } from "../../../../common-interfaces/interfaces";
import { deepCopy } from "../../../../helpers/utils";
import { useOnGet } from "../../../../hooks/useOnGet";
import { useOnSet } from "../../../../hooks/useOnSet";

const ServiceFastCreate = () => {
  const [currentSelectedComponent, setCurrentSelectedComponent] = useRecoilState<IComponent>(currentSelectedComponentAtom)
  const [allServices] = useRecoilState<IService[]>(uneditableServiceAtom)
  const [newService, setNewService] = useRecoilState<IService>(newServiceAtom);

  const resetAtom = useResetRecoilState(newServiceAtom);
  const { id } = useOnGet()
  const { comp } = useOnSet()

  const handleChange = async (_: any, serviceName: string | null) => {
    if (!serviceName) return;
    const service = allServices?.find((s: IService) => s.name === serviceName);
    const tempServ: IService = await deepCopy(service);
    tempServ._id = id();
    tempServ.editable = true;
    setNewService(tempServ);
  };


  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    resetAtom();
    setOpen(true);
  };

  const handleDialogClose = () => {

    setOpen(false);
  };

  const handleConfirm = async () => {
    const updatedComponent = { ...currentSelectedComponent, services: [...(currentSelectedComponent?.services ?? []), newService] }
    await comp(updatedComponent)
    setCurrentSelectedComponent(updatedComponent)
  }


  return (
    <>
      <Button onClick={handleDialogOpen} className="addDashboardButton">
        <Typography mr={0.5} fontSize={13}>Service</Typography>
        <AddCircleOutlineIcon />
      </Button>
      {/* DIALOG FOR CREATE */}
      <ControlWhiteDialog open={open} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Create Service</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box width="100%" marginTop={4}>
              <Typography>Select an existing service:</Typography>
              <Box minWidth={120}>
                <Autocomplete
                  onChange={handleChange}
                  options={allServices?.filter((s: IService) => !s.editable && s.brand === 'k-array').map(s => s.name) ?? []}
                  fullWidth
                  value={newService?.name}
                  renderInput={(params) => <TextField {...params} label="Service" />}
                  PaperComponent={CustomPaper}
                />
                {newService.description && (
                  <Box mt={3}>
                    <Typography>Description:</Typography>
                    <Typography mt={1} pl={1}>
                      {newService?.description}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          {newService?.name && <Button onClick={handleConfirm}>Confirm</Button>}
        </DialogActions>
      </ControlWhiteDialog>
    </>
  );
}

export default ServiceFastCreate

const CustomPaper = (props: any) => (
  <Paper
    {...props}
    sx={{
      backgroundColor: "#282828",
      border: "var(--primary_border)",
      color: "var(--grey_background-color)",
      "& .MuiAutocomplete-listbox": {
        "& .MuiAutocomplete-option[aria-selected='true']": {
          color: "var(--secondary-color)",
          "&.Mui-focused": {
            color: "var(--secondary-color)",
          },
        },
      },
      "& .MuiAutocomplete-listbox .MuiListSubheader-root": {
        bgcolor: '#222',
        color: "var(--secondary-color)",
      },
      ".MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
        bgcolor: "#333",
        color: "var(--secondary-color)",
      },
    }}
  />
);
