import { FC } from "react";
import { Check } from "@mui/icons-material";
import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import { ERequestSubtype, ERequestStatus, ERequestStatusColor, ERequestType, INotificationRequest } from "../../../../common-interfaces/interfaces";
import { useRecoilState } from "recoil";
import { allRequestsAtom } from "../../utils/requestAtoms";
import { useApiWrapper } from "../../../../hooks/useApiWrapper";
import useOnCopy from "../../../../hooks/useOnCopy";
import { PrintError } from "../../../../helpers/utils";

const AcceptButton: FC<{
  handleAction: (value: ERequestStatus) => void,
  requestID: string,
  downloadIcon:boolean
}> = ({ handleAction, requestID, downloadIcon
   }) => {

  const [allRequests] = useRecoilState(allRequestsAtom);

  const { getWithAuth, postWithAuth } = useApiWrapper()

  const { handleAddCustom, handleCopy } = useOnCopy()

  const handleShareAccept = async (req: INotificationRequest) => {
    try {
      const res = await getOriginal(req);
      await handleSaveCustom(res, req.subtype);
    } catch (e) {
    }
  };

  const getOriginal = async (req: INotificationRequest) => {
    const ep = `api/${req.subtype}?_id=${req.idObject}`;
    const res = await getWithAuth(ep, {});
    return res.data[0]
  };

  const handleClick = async () => {
    const req = allRequests.find(r => r._id === requestID);
    if (req && req?.type === ERequestType.SHARE && downloadIcon) {
      await handleShareAccept(req);
    }
    handleAction(ERequestStatus.ACCEPTED);
  };

  const handleSaveCustom = async (original: any, subtype: ERequestSubtype) => {
    let base = await handleCopy(original, subtype, false)
    try {
      const ep = `api/${subtype}`;
      await postWithAuth(ep, base);
      await handleAddCustom(base, subtype)
    } catch (e) {
      PrintError([e])
    }
  };

  return <Button
    sx={{paddingY: '5px',}}
    onClick={handleClick}
    title="Accept"
  >
    {downloadIcon ? <Download sx={{ fontSize: 18, color: ERequestStatusColor.COMPLETED }} />: <Check sx={{ fontSize: 18, color: ERequestStatusColor.ACCEPTED, }} />}
  </Button>
}

export default AcceptButton