import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  IComponent,
  IDashboard,
  IInstallation,
  IService,
  IUser,
} from "../common-interfaces/interfaces";
import {
  allComponentsAtom,
  selectedDashboardAtom,
  selectedInstallationAtom,
} from "../recoil/atoms";
import { useApiWrapper } from "./useApiWrapper";

import { Print, deepCopy } from "../helpers/utils";
import { useOnGet } from "./useOnGet";
import { selectedContainerAtom } from "../recoil/workspace";
import { enqueueSnackbar } from "notistack";
import { ErrorAutoHide } from "../config/general.config";
import { currentSelectedComponentAtom } from "../recoil/Atom";

export function useOnSet() {
  const apiWrapper = useApiWrapper();
  const [allComponents, setAllComponents] = useRecoilState(allComponentsAtom);
    const [,setCurrentSelectedComponent] = useRecoilState(currentSelectedComponentAtom)
  const [selectedInstallation, setSelectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
  const [, setSelectedDashboard] = useRecoilState(selectedDashboardAtom);
  const [, setSelectedContainer] = useRecoilState(selectedContainerAtom);
  const GET = useOnGet();
  let { dashboardId } = useParams();

  return {
    installation: modifyInstallation(),
    dash: modifyDash(),
    comp: modifyComp(),
    service: modifyService(),
    syncAllComp: syncAllComp(),
    plant: modifyPlant(),
    user: modifyUser(),
  };

  function modifyDash(): (newDash: IDashboard) => Promise<void> {
    return async (newDash): Promise<void> => {
      if (!selectedInstallation) return;
      let tmpInstallation: IInstallation = await deepCopy(selectedInstallation);
      let oldDash = tmpInstallation.configuration.dashboards.find(
        (d) => d._id === dashboardId
      );

      if (!oldDash) return;
      oldDash = newDash;
      setSelectedDashboard(newDash);
      tmpInstallation.configuration.dashboards =
        tmpInstallation.configuration.dashboards.map((d) =>
          d._id === newDash._id ? newDash : d
        );
      editInstallation(tmpInstallation);
    };
  }

  function syncAllComp(): (comp: IComponent) => Promise<void> {
    return async (comp): Promise<void> => {
      let tmpAllComps: IComponent[] = await deepCopy(allComponents);
      let tmpComp = tmpAllComps.find((c) => c._id === comp._id);
      if (!tmpComp) {
        tmpAllComps.push(comp);
      } else {
        tmpComp = await deepCopy(comp);
      }
      if (comp?.components) {
        comp.components.forEach(async (compInside) => {
          let tempCompInside = await deepCopy(compInside);
          let isThere = tmpAllComps.find((c) => c._id === compInside._id);
          if (isThere) {
            isThere = tempCompInside;
          } else {
            tmpAllComps = [...tmpAllComps, tempCompInside];
          }
        });
      }
      setAllComponents(tmpAllComps);
    };
  }

  function modifyInstallation(): (
    tmpInstallation: IInstallation
  ) => Promise<void> {
    return async (tmpInstallation): Promise<void> => {
      editInstallation(tmpInstallation);
    };
  }

  function modifyComp(): (newComp: IComponent) => Promise<void> {
    return async (newComp): Promise<void> => {
      if (!selectedInstallation) return;

      let tmpInstallation: IInstallation = await deepCopy(selectedInstallation);
      let dash = tmpInstallation.configuration.dashboards.find(
        (d) => d._id === dashboardId
      );
      if (!dash) return;

      dash.components = dash.components.map((c) => {
        if (c.components) {
          c.components = c.components.map((compInside) =>
            compInside._id === newComp._id ? newComp : compInside
          );
        }
        return c._id === newComp._id ? newComp : c;
      });
      setSelectedDashboard(dash);
      editInstallation(tmpInstallation);
    };
  }

  function modifyService(): (
    newService: IService,
    compID: string
  ) => Promise<void> {
    return async (newService, compID): Promise<void> => {
      if (!selectedInstallation) return;
      let tmpInstallation: IInstallation = await deepCopy(selectedInstallation);
      let dash = tmpInstallation.configuration.dashboards.find(
        (d) => d._id === dashboardId
      );
      if (!dash) return;
      let comp: IComponent = await deepCopy(GET.comp(compID));
      if (!comp || !comp.services) return;

      //TODO REFACTOR
      comp.services = [...comp.services.filter(s => s._id !== newService._id), newService];
      dash.components = dash.components.map((c) => {
        if (c.components) {
          c.components = c.components.map((compInside) =>
            compInside._id === comp._id ? comp : compInside
          );
          if (c.components.find((c) => c._id === comp._id)) {
            setSelectedContainer(c);
          }
        }
        return c._id === comp._id ? comp : c;
      });
      setSelectedDashboard(dash);
      editInstallation(tmpInstallation);
      setCurrentSelectedComponent(comp);
    };
  }

  async function editInstallation(tmpInstallation: any) {
    setSelectedInstallation(tmpInstallation);
    apiWrapper
      .putWithAuth(`api/installation/${tmpInstallation._id}`, tmpInstallation)
      .then((res) => {
        Print(["----->", res.status, "<----"]);
        if (res.status !== 200) {
          return enqueueSnackbar(
            "was not possibile to apply change, please reload the page",
            {
              variant: "error",
              autoHideDuration: ErrorAutoHide
            }
          );
        }
      })
      .catch((err) => {
        //
      });
  }

  function modifyPlant(): (tmpPlant: IInstallation) => Promise<void> {
    return async (tmpPlant): Promise<void> => {
      apiWrapper
        .putWithAuth(`api/plant/${tmpPlant._id}`, tmpPlant)
        .then((res) => {
          Print(["----->", res.status, "<----"]);
          if (res.status !== 200) {
            return enqueueSnackbar(
              "was not possibile to apply change, please reload the page",
              {
                variant: "error",
                autoHideDuration: ErrorAutoHide
              }
            );
          }
        })
        .catch((err) => {
          //
        });
    };
  }

  function modifyUser(): (tmpUser: IUser) => Promise<void> {
    return async (tmpUser): Promise<void> => {
      apiWrapper
        .putWithAuth(`api/user/${tmpUser._id}`, tmpUser)
        .then((res) => {
          Print(["----->", res.status, "<----"]);
          if (res.status !== 200) {
            return enqueueSnackbar(
              "was not possibile to apply change, please reload the page",
              {
                variant: "error",
                autoHideDuration: ErrorAutoHide
              }
            );
          }
        })
        .catch((err) => {
          //
        });
    };
  }
}
