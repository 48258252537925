import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useState } from "react";
import { IComponent } from "../../../../common-interfaces/interfaces";

export default function ButtonsArray(props: any) {
  const [comp] = useState(props.component);
  const [status, setStatus] = useState<number>(1);

  const SIZE = `${100 / (comp.components.length > 0 ? comp.components.length : 3)}%`;
  const unselectedColor = `${comp.backgroundColor}aa`;

  return (
    <Box
      className="center"
      sx={{ width: "100%", height: "100%" }}
      title={comp.name?.toUpperCase()}
    >
      <ButtonGroup
        variant="contained"
        sx={{ width: "98%", height: "80%", border: "var(--primary_border)" }}
      >
        {comp.components.length > 0 ? (
          comp.components.map((c: IComponent, index: number) => (
            <Button
              key={index}
              sx={{
                height: 95,
                maxWidth: SIZE,
                width: SIZE,
                background:
                  status === index ? comp.backgroundColor : unselectedColor,
              }}
              onClick={() => setStatus(index)}
            >
              {c.name}
            </Button>
          ))
        ) : (
          [1, 2, 3].map(i => (
            <Button
              key={i}
              style={{
                minWidth: '33%',
                maxWidth: '33%',
                width: '33%',
              }}
              sx={{
                p: 0,
                height: "100%",
                background:
                  status === i ? "var(--secondary-color)" : unselectedColor,
              }}
              onClick={() => setStatus(i)}
            >
              <Typography fontSize={10}>{i}</Typography>
            </Button>
          ))
        )}
      </ButtonGroup>
    </Box>
  );
}
