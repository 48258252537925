import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material';
import BodyRawDialog from './BodyRawDialog';
import { isParamSegment, removeParamIdentifier } from '../common/utils';
import { useRecoilState } from 'recoil';
import { IQuery } from '../../../../common-interfaces/interfaces';
import { serviceParamsAtom } from '../../../../recoil/Atom';
import { emptyBodyRow } from '../../../../config/config';
import { IRequestBody, IBodyRow } from '../../../../local-interfaces/local-interfaces';

const firstButtonStyle: CSSProperties = { marginLeft: 1 }
const secondButtonStyle = { height: '20px', my: 2 }

const BodyRaw: FC<{
  bodyProps: IRequestBody,
  dispatch: (body: IRequestBody) => Promise<void>
}> = ({ bodyProps, dispatch }) => {
  const [serviceParams] = useRecoilState<IQuery[]>(serviceParamsAtom)

  const [splittedBody, setSplittedBody] = useState<IBodyRow[]>([])
  const [editingRow, setEditingRow] = useState<IBodyRow>(emptyBodyRow)
  const [editingIndex, setEditingIndex] = useState<number>(-1)
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!bodyProps.raw)
      return
    //removes the first and last {}
    const body = parseBodyToJSON(bodyProps.raw)
    const tmpRows = Object.keys(body).map(bodyKey => {
      return {
        key: bodyKey,
        hasParam: body[bodyKey] ? isParamSegment(body[bodyKey]) : false,
        value: body[bodyKey],
        query: serviceParams.find(d => d.key === removeParamIdentifier(body[bodyKey]))
      }
    })
    setSplittedBody(tmpRows)
  }, [bodyProps]);

  useEffect(() => {
    handleChangedRows(editingRow)
  }, [editingRow]);

  const parseBodyToJSON = (body: string) => {
    const parsedBody = body.replace(/"{0,2}@@([^@]+)@@"{0,2}|@@([^@]+)@@/g, '"@@$1@@"')
    return JSON.parse(`${parsedBody}`);
  }

  const handleConfirm = () => {
    let tmp = ""
    splittedBody.forEach(row => {
      if (row.key !== "" && row.value !== "")
        tmp += `"${row.key}": "${row.value}",`
    })
    if (tmp !== "")
      tmp = tmp.slice(0, -1)
    dispatch({
      ...bodyProps,
      raw: `{${tmp}}`
    })
  }

  const handleDialogClose = () => {
    setSplittedBody(splittedBody.filter(r => r.key && r.value))
    handleConfirm()
    setOpen(false);
  };

  const handleChangedRows = async (editedRow: IBodyRow) => {
    const tmpSplittedBody: IBodyRow[] = [...splittedBody]
    tmpSplittedBody[editingIndex] = { ...editedRow };
    setSplittedBody(tmpSplittedBody);
  };

  const handleAddRow = () => {
    setSplittedBody([...splittedBody, emptyBodyRow])
    setEditingIndex(splittedBody.length)
    setEditingRow(emptyBodyRow)
    setOpen(true)
  }

  const handleRowClick = (index: number) => {
    setEditingIndex(index)
    setEditingRow(splittedBody[index])
    setOpen(true);
  }

  return (
    <>
      <Box width={'100%'} height={'100%'} boxSizing={'border-box'} p={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
        <Typography textAlign='start' className='no-underline-title'>{'{'}</Typography>
        {splittedBody.map((d, i) => (
          <Button key={i} sx={firstButtonStyle} onClick={() => handleRowClick(i)} style={{ textTransform: 'none' }}>
            <Typography key={d.key} color={'#ccc'}>{d.key} : {d.value}</Typography>
          </Button>
        ))}
        <Button variant='contained' sx={secondButtonStyle} onClick={handleAddRow} color="primary">
          <Typography>+</Typography>
        </Button>
        <Typography textAlign={'end'} className='no-underline-title'>{'}'}</Typography>
      </Box>
      <BodyRawDialog open={open} editingRow={editingRow} setEditingRow={setEditingRow} handleDialogClose={handleDialogClose} />
    </>
  )

}
export default BodyRaw