import { Box, CircularProgress } from "@mui/material";
import { EComponentType, IComponent } from "../../../../../common-interfaces/interfaces";
import ContainerComponent from "../utils/ContainerComponent";
import { CSSProperties, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { selectedComponentIDsAtom } from "../../../../../recoil/atoms";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";
import { useOnGet } from "../../../../../hooks/useOnGet";
import { RxSlider } from "react-icons/rx";
import { AiFillSliders } from "react-icons/ai";
import { PiSubtitles } from "react-icons/pi";
import ComponentIcon from "../utils/ComponentIcon";

export default function (props: any) {
  const [comp, setComp] = useState<IComponent>();
  const [isOver, setIsOver] = useState(false);
  const [selectedComponentIDs] = useRecoilState<string[]>(selectedComponentIDsAtom);

  const GET = useOnGet();

  useEffect(() => {
    setIsOver(props.over);
  }, [props.over]);

  useEffect(() => {
    setComp(props.comp);
  }, [props.comp]);

  const componentProperties: CSSProperties = {
    fontWeight: "bold",
    width: "100%", //border
    height: "100%",
    color: props.comp.color,
    background: isOver ? "red" : props.comp.backgroundColor,
    boxShadow:
      selectedComponentIDs.includes(props.comp._id)
        ? "0px 4px 15px 4px  var(--secondary-color)"
        : selectedComponentIDs.some((x) => {
          const comp = GET.comp(x);
          return comp?.slaves?.includes(props.comp._id);
        })
          ? "0px 4px 15px 4px #756b5a"
          : "none",
  };

  const containerProperties: CSSProperties = {
    fontWeight: "bold",
    width: "100%", //border
    height: "100%",
    color: props.comp.color,
    background: isOver ? "var(--secondary-color)" : props.comp.backgroundColor,
    boxShadow: selectedComponentIDs.find((x) => x === props.comp._id)
      ? "0px 4px 15px 4px  var(--secondary-color)"
      : "none",
    opacity: 0.7,
  };

  switch (props.comp.type) {
    case EComponentType.Button:
      return (
        <Box
          style={componentProperties}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ComponentIcon comp={comp} />
        </Box>
      );
    case EComponentType.Switch:
      return (
        <Box
          style={componentProperties}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ComponentIcon comp={comp} size={17.5} style={{position: 'absolute', color: comp?.backgroundColor, left: '22.5%'}}/>
          <ToggleOnIcon style={componentProperties} />
        </Box>
      );
    case EComponentType.Slider:
      return (
        <AiFillSliders style={componentProperties} />
      );
    case EComponentType.SliderLinear:
      return (
        <RxSlider style={componentProperties} />
      );
    case EComponentType.Label:
      return (
        <PiSubtitles style={componentProperties} />
      );
    case EComponentType.ButtonsArray:
      return (
        <PiSubtitles style={componentProperties} />
      );
    case EComponentType.Container:
      if (comp)
        return (
          <ContainerComponent
            comp={comp}
            style={containerProperties}
            isOver={isOver}
          ></ContainerComponent>
        );
      return <></>;
    case EComponentType.None:
      return (
        <Box
          className="center"
          style={componentProperties}
        >
          <CircularProgress
            sx={{
              color: "white",
            }}
          />
        </Box>
      );
    default:
      return (
        <BrowserNotSupportedIcon style={componentProperties} />
      );
  }
}
