import { Box, Typography, Stepper, Step, StepLabel, StepContent, Button, Grid, TextField, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import FirstStep_SelectPlant from "./steps/FirstStep_SelectPlant";
import { useRecoilState, useResetRecoilState } from "recoil";
import { createInstallationActiveStepAtom, creatingPlantAtom, wizardSelectedPlantAtom, tempPlantAtom } from "../../../recoil/CreateInstallationAtoms";
import { useNavigate } from "react-router-dom";
import ThirdStep_CreatePlant from "./steps/ThirdStep_CreatePlant";
import ReactLoading from "react-loading";
import LinkInstallationToClient from "../common/LinkInstallationToClient";
import { IPlantFull, IPlant, IInstallation } from "../../../common-interfaces/interfaces";
import { Print, PrintError, deepCopy } from "../../../helpers/utils";
import { useOnSet } from "../../../hooks/useOnSet";
import { allPlantsFullAtom } from "../../../recoil/atoms";
import { useApiWrapper } from "../../../hooks/useApiWrapper";

const firstButtonStyle = { mt: 1, mr: 1 }
const secondButtonStyle = { minWidth: "100%" }
const thirdButtonStyle = { mt: 1, ml: "auto" }
const dividerStyle = { my: 2 }
const textfieldStyle = { padding: 0, ml: 3, minWidth: "240px", width: "240px" }

const CreateInstallationWizard = () => {
  const apiWrapper = useApiWrapper();
  const navigate = useNavigate();
  const SET = useOnSet();

  const resetCreatingState = useResetRecoilState(creatingPlantAtom);
  const resetPlant = useResetRecoilState(wizardSelectedPlantAtom);
  const resetTempPlant = useResetRecoilState(tempPlantAtom);
  const resetSteps = useResetRecoilState(createInstallationActiveStepAtom);
  const [activeStep, setActiveStep] = useRecoilState(createInstallationActiveStepAtom);
  const [creatingPlant, setCreatingPlant] = useRecoilState<boolean>(creatingPlantAtom);
  const [selectedPlant, setSelectedPlant] = useRecoilState<IPlantFull | undefined>(wizardSelectedPlantAtom);
  const [tempPlant, setTmpPlant] = useRecoilState<IPlant>(tempPlantAtom);
  const [allPlantsFull, setAllPlantsFull] = useRecoilState<IPlantFull[]>(allPlantsFullAtom);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nameIsTaken, setNameIsTaken] = useState<boolean>(false);
  const [plantsName, setPlantsName] = useState<string[]>([]);
  const [tmpNewPlantName, setTmpNewPlantName] = useState<string>("");
  // const [isValidPlant, setIsValidPlant] = useState<boolean>(false);

  useEffect(() => {
    resetCreatingState();
    resetPlant();
    resetTempPlant();
    resetSteps();
    setCreatingPlant(false);
    getAllPlantsName();
  }, []);

  // useEffect(() => {
  //   let isValid = Object.values(tempPlant).every(Boolean);
  //   setIsValidPlant(isValid);
  // }, [tempPlant]);

  const steps = [
    { label: "Plant Selection", description: `Select an existing plant from cloud or create a new Installation`, content: <FirstStep_SelectPlant></FirstStep_SelectPlant> },
    { label: "Link To Client", description: `Select the client to link installation with:`, content: <LinkInstallationToClient></LinkInstallationToClient> },
    { label: `Plant ${tempPlant.name} Info`, description: `Fill in all the fields in the forms to complete the creation of the plant:`, content: <ThirdStep_CreatePlant></ThirdStep_CreatePlant> },
  ];

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        creatingPlant && !selectedPlant ? setActiveStep(1) : setActiveStep(2);
        break;
      case 1:
        setActiveStep(2);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    if (activeStep === 1) {
      resetPlant();
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getAllPlantsName = async () => {
    setIsLoading(true);
    try {
      const res = await apiWrapper.getWithAuth("api/plant", {});
      const plants: IPlantFull[] = res.data;
      const tmpPlantsName = plants.map((item) => {
        return item.plant.name.toLowerCase();
      });
      setPlantsName(tmpPlantsName);
    } catch (e) {
      PrintError([e])
    }
    setIsLoading(false);
  };

  const handlePlantNameOnChange = async (e: any) => {
    const name = e.target.value
    setTmpNewPlantName(name);
    setNameIsTaken(plantsName.includes(name.toLowerCase()));
  };

  const handlePlantNameBlur = async (e: any) => {
    const name = e.target.value
    setNameIsTaken(plantsName.includes(name.toLowerCase()));
    let newPlant = await deepCopy(tempPlant);
    newPlant.name = name;
    setTmpPlant(newPlant);
  };

  const createNewFullPlant = async () => {
    // if (!isValidPlant) {
    //   setIsLoading(false);
    //   return console.error("no valid plant -->", isValidPlant);
    // }
    let plant = await deepCopy(tempPlant);
    delete plant.id;
    const body = {
      plant: plant,
      clients: selectedPlant?.clients ?? [],
    };
    const url = `api/plant`;
    try {
      let res = await apiWrapper.postWithAuth(url, body);
      setAllPlantsFull([...allPlantsFull, res.data]);
      setSelectedPlant(res.data);
      return res.data;
    } catch (e) {
      Print([e]);
    }
  };
  const handleCreation = () => {
    setCreatingPlant(true);
    setActiveStep(1);
  };

  const handlePlant2Installation = async (plant = selectedPlant) => {
    try {
      const url = `api/installation/plant2installation`;
      const res = await apiWrapper.postWithAuth(url, plant);
      const newInstallation: IInstallation | undefined = res.data;
      if (!newInstallation) {
        return setIsLoading(false);
      }
      newInstallation.devices = []
      const tmpInstallation = await deepCopy(newInstallation);
      await SET.installation(tmpInstallation);
      let tmpAllPlantsFull = await deepCopy(allPlantsFull)
      tmpAllPlantsFull.push({
        ...plant,
        installationName: tmpInstallation.name,
        lastEdit: tmpInstallation.lastEdit,
        creationDate: tmpInstallation.creationDate,
      })
      setAllPlantsFull(tmpAllPlantsFull)
      setIsLoading(false);
      navigate(`/installation/${newInstallation._id}`);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handlePlantCreation = async () => {
    setIsLoading(true);
    let plant = await createNewFullPlant();
    if (!plant) return;
    await handlePlant2Installation(plant);
    resetTempPlant();
  };

  const handleCreateInstallation = async () => {
    setIsLoading(true);
    await handlePlant2Installation();
  };
  if (isLoading) {
    return (
      <Grid
        display={"flex"}
        minHeight={"650px"}
        maxHeight={"650px"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <ReactLoading type={"bars"} color={"#fff"} width={"60px"} />
      </Grid>
    );
  }

  return (
    <Box minHeight={"650px"} maxHeight={"650px"}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel optional={index !== 0 ? <Typography variant="caption">optional</Typography> : null}>
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box>{step.content}</Box>
              <Box display={'flex'} justifyContent={'flex-end'} marginTop={4}>
                <Box width={"100%"}>
                  {activeStep !== 0 && (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleBack}
                        sx={firstButtonStyle}
                        disabled={activeStep === 0 && !creatingPlant}
                      >
                        Back
                      </Button>
                      {creatingPlant && activeStep !== steps.length - 1 && (
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={firstButtonStyle}
                        >
                          Next
                        </Button>
                      )}
                      {creatingPlant && activeStep === steps.length - 1 && (
                        <Button
                          variant="contained"
                          onClick={handlePlantCreation}
                          sx={firstButtonStyle}
                        // disabled={!isValidPlant}
                        >
                          Create Installation
                        </Button>
                      )}
                    </>
                  )}
                  {activeStep === 0 && (
                    <Box
                      width={"100%"}
                      height={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Box py={"10px"}>
                        <Button
                          variant="contained"
                          onClick={handleCreateInstallation}
                          sx={secondButtonStyle}
                          disabled={!selectedPlant}
                        >
                          Create Installation
                        </Button>
                      </Box>
                      <Divider sx={dividerStyle} />
                      <Box display={"flex"} flexDirection={"row"}>
                        <TextField
                          error={nameIsTaken}
                          label="Installation Name"
                          variant="standard"
                          type="text"
                          value={tmpNewPlantName}
                          onChange={handlePlantNameOnChange}
                          onBlur={handlePlantNameBlur}
                          sx={textfieldStyle}
                          helperText={nameIsTaken && "Installation name already taken"}
                        />
                        <Button
                          variant="contained"
                          disabled={!tmpNewPlantName || nameIsTaken}
                          onClick={handleCreation}
                          sx={thirdButtonStyle}
                        >
                          Create New Installation
                        </Button>
                      </Box>
                      <Typography fontSize={10} ml={"20px"} mt={1}>A cloud plant will be created automatically.</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default CreateInstallationWizard