import { FC } from "react";
import { Box, ButtonGroup } from "@mui/material";
import DeleteRequestButton from "./buttons/DeleteRequestButton";
import AcceptButton from "./buttons/AcceptButton";
import DeclineButton from "./buttons/DeclineButton";
import ViewMessageButton from "./buttons/ViewMessageButton";
import { INotificationRequest, ERequestStatus, ERequestType, IUser } from "../../../common-interfaces/interfaces";
import useRequestNotificationUtils from "../hooks/useRequestNotificationUtils";
import CompleteButton from "./buttons/CompleteButton";
import { useRecoilState } from "recoil";
import { userProfileAtom } from "../../../recoil/atoms";

const RequestActions: FC<{ request: INotificationRequest }> = ({ request }) => {
  const [user] = useRecoilState<IUser | undefined>(userProfileAtom)

  const { deleteRequest, handleChangeReqValue } = useRequestNotificationUtils()

  const handleAction = (value: ERequestStatus) => {
    handleChangeReqValue(request, value)
  };

  const handleDelete = () => {
    deleteRequest(request._id);
  };

  const handleSendRequest = () => {
    alert(`Request ${request._id} has been sent.`);
  };

  if (request.from === user?.username) {
    return <DeleteRequestButton handleDelete={handleDelete} requestID={request._id} />
  }

  if (request.type === ERequestType.SHARE)
    return (
      <Box>
        <ButtonGroup variant="contained" color="primary" >
          {request.status === ERequestStatus.PENDING && (
            <AcceptButton handleAction={handleAction} requestID={request._id} downloadIcon={false} />
          )}
          {request.status === ERequestStatus.PENDING && (
            <DeclineButton handleAction={handleAction} requestID={request._id} />
          )}
          {/*
          REMOVE FOR SEND REQUEST
          {(request.status === ERequestStatus.REFUSED || request.status === ERequestStatus.ACCEPTED) && (
            <SendRequestButton handleSendRequest={handleSendRequest} requestID={request._id} />
          )} 
           */}
          {request.status === ERequestStatus.ACCEPTED && (
            <AcceptButton handleAction={handleAction} requestID={request._id} downloadIcon={true} />
          )}
          {request.status !== ERequestStatus.PENDING && (
            <DeleteRequestButton handleDelete={handleDelete} requestID={request._id} />
          )}
        </ButtonGroup>
      </Box >
    );
  if (request.type === ERequestType.MESSAGE)
    return (
      <ButtonGroup variant="contained" color="primary" >
        {request.status === ERequestStatus.PENDING && (
          <AcceptButton handleAction={handleAction} requestID={request._id} downloadIcon={false} />
        )}
        {request.status === ERequestStatus.PENDING && (
          <DeclineButton handleAction={handleAction} requestID={request._id} />
        )}
        {request.status !== ERequestStatus.PENDING && (
          <DeleteRequestButton handleDelete={handleDelete} requestID={request._id} />
        )}
        {request.status === ERequestStatus.ACCEPTED && (
          <ViewMessageButton requestID={request._id} />
        )}
      </ButtonGroup>
    )
  return <></>
};

export default RequestActions;
