import axios from "axios";
import { useRecoilState, useResetRecoilState } from "recoil";
import { ILoggedUser } from "../local-interfaces/local-interfaces";
import { loggedUserAtom, userProfileAtom } from "../recoil/atoms";
import { IUser } from "../common-interfaces/interfaces";
import { Print, PrintError } from "../helpers/utils";

export const BASE_URL: string = process.env.REACT_APP_PORT
  ? `${process.env.REACT_APP_APIBASEURL}:${process.env.REACT_APP_PORT}`
  : `${process.env.REACT_APP_APIBASEURL}`;

function useOnUser() {

  const [loggedUser] = useRecoilState<ILoggedUser | undefined>(loggedUserAtom);
  const resetUser = useResetRecoilState(userProfileAtom);
  const reset = useResetRecoilState(loggedUserAtom);
  return {
    login: login(),
    logout,
  };

  function login(): (
    username: string,
    password: string
  ) => Promise<ILoggedUser | undefined> {
    return async (username, password): Promise<ILoggedUser | undefined> => {
      const url = `${BASE_URL}/auth/authenticate`;
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      })
        .then(async (response: any) => {
          if (response.status === 200) {
            const res: IUser = await response.json();
            return { ...res, issued: Date.now() };
          }
          return undefined;
        })
        .catch((e) => {
          throw e;
        });
    };
  }

  async function logout() {
    const body = { refresh_token: loggedUser?.refresh_token };
    try {
      let additional: any = {
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };

       await axios({
        url: `${BASE_URL}/auth/logout`,
        method: "POST",
        ...additional,
      });

    } catch (e) {
      PrintError([e]);
    }finally{
      reset();
      resetUser()
      localStorage.removeItem("logged_user");
    }
  }
}

export { useOnUser };
