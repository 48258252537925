import { useCallback, useRef, ChangeEvent, FC, useState, useEffect } from "react";
import { deepCopy, convertImageToBase64, no_image, PrintError } from "../../../../helpers/utils";
import { IInstallation, IImage, IDashboard } from "../../../../common-interfaces/interfaces";
import { Box, Button, CardMedia } from "@mui/material";
import { BASE_URL, useApiWrapper } from "../../../../hooks/useApiWrapper";
import { useRecoilState } from "recoil";
import { selectedInstallationAtom } from "../../../../recoil/atoms";
import { useOnSet } from "../../../../hooks/useOnSet";
import { enqueueSnackbar } from "notistack";
import { ErrorAutoHide } from "../../../../config/general.config";

const DashboardPreviewImage: FC<{
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  dash: IDashboard
}> = ({
  loading,
  setLoading,
  dash

}) => {
    const [selectedInstallation] = useRecoilState<IInstallation>(selectedInstallationAtom);
    const [isLoading, setIsLoading] = useState<boolean>(loading)
    useEffect(() => {
      setIsLoading(loading)
    }, [loading]);

    const SET = useOnSet();
    const apiWrapper = useApiWrapper();

    const handleImageChange = useCallback(async (image: File) => {
      setLoading(true);
      const res = await handleImageUpload(image);
      if (!res) {
        enqueueSnackbar("was not possible to update image, please try again later", { variant: 'error', autoHideDuration: ErrorAutoHide });
        return;
      }
      const tmpSelectedInstallation: IInstallation = await deepCopy(selectedInstallation);
      const dashConfig = tmpSelectedInstallation.configuration.dashboards.find((x) => x._id === dash._id);
      if (!dashConfig) return;
      const iImage: IImage = {
        _id: res.data._id,
        name: res.data.name,
        alt: res.data.name,
        src: res.data.src,
        ext: res.data.ext,
      };
      dashConfig.img = iImage;
      await SET.installation(tmpSelectedInstallation);
      setLoading(false);
    }, [selectedInstallation, dash._id]);

    const removeImage = async () => {
      const tmpSelectedInstallation: IInstallation = await deepCopy(selectedInstallation);
      const dashConfig = tmpSelectedInstallation.configuration.dashboards.find((x) => x._id === dash._id);
      if (!dashConfig) return;
      dashConfig.img = undefined;
      await SET.installation(tmpSelectedInstallation);
    }
    const handleImageUpload = useCallback(async (image: File) => {
      try {
        const data = await convertImageToBase64(image);
        const filenameextension = image.name.replace(/^.*[\\\/]/, "");
        let name = filenameextension.substring(0, filenameextension.lastIndexOf(".")).replace(/ /g, "_");
        const body = { name, data };
        const url = "api/image";
        return await apiWrapper.postWithAuth(url, body);
      } catch (error) {
        PrintError(["Error converting image to base64:", error]);
      }
    }, [apiWrapper]);

    const getImage = () => {
      const dash_img = selectedInstallation?.configuration.dashboards.find((x) => x._id === dash._id)?.img;
      return dash_img ?? no_image;
    };

    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        handleImageChange(file);
      }
    };

    const handleFileSelect = () => {
      fileInputRef.current?.click();
    };
    return (
      <Box sx={{ position: 'relative', zIndex: -1 }}>
       {dash?.img && <Button sx={{ height: '15px', top: 0, right: 0, position: 'absolute' }} onClick={removeImage}>x</Button>}
        <input
          type="file"
          accept="image/jpeg, image/png, image/jpg"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          disabled={isLoading}
        />
        <CardMedia
          component="img"
          height="184"
          sx={{
            px: "2.5%",
            width: "95%",
            cursor: isLoading ? "not-allowed" : "pointer",
            objectFit: 'cover',
          }}
          draggable={false}
          onClick={handleFileSelect}
          image={`${BASE_URL}/${getImage()?.name}.${getImage()?.ext}`}
        />

      </Box>
    )
  }

export default DashboardPreviewImage